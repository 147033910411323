import FlagIcon from "@mui/icons-material/Flag";
import { Box, Typography } from "@mui/material";
import { formateDate } from "../../utils/formate";

const NewsItem = (item) => {
  return (
    <Box
      key={item.update_at + item.team_name + item.challenge_name}
      sx={{
        mb: 3,
      }}
    >
      <Box>
        <Typography
          variant="caption"
          sx={{
            color: "#c1c1c1",
          }}
        >
          {formateDate(item.update_at)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            mr: 1,
            color: item.is_success ? "#B0FF20" : "red",
          }}
        >
          {item.team_name}
        </Typography>
        <FlagIcon
          sx={{
            color: item.is_success ? "B0FF79" : "red",
          }}
        />
        <Typography
          sx={{
            ml: 1,
            color: item.is_success ? "#B0FF20" : "red",
          }}
        >
          {item.challenge_name}
        </Typography>
      </Box>
    </Box>
  );
};

const NewList = ({ solveList }) => {
  return (
    <div>
      {solveList?.map((item) => (
        <NewsItem
          key={
            item.update_at +
            item.team_name +
            item.challenge_name +
            Math.random() +
            "news"
          }
          {...item}
        />
      ))}
    </div>
  );
};

export default NewList;
