import React, { useState, useEffect } from "react";
import { AppBar, Box, Container, IconButton, Typography, Toolbar, Avatar, Button, Menu, MenuItem } from "@mui/material";
import { NetworkPing } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { logout } from "../../apis/user";
import { delCookie } from "../../utils/formate";

const pages = [
    {
        name: "竞赛",
        path: "/",
    },
    {
        name: "排行榜",
        path: "/range",
    },
];

function ResponsiveAppBar() {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [countdownStart, setCountdownStart] = useState("");
    const [countdownRound, setCountdownRound] = useState("");
    const [phaseStart, setPhaseStart] = useState("距离比赛开始:");
    const [phaseRound, setPhaseRound] = useState("本轮剩余时间:");
    const navigate = useNavigate();

    // 倒计时逻辑
    useEffect(() => {
        const timerStart = setInterval(() => {
            const { countdownText, phaseText } = getCountdown(getDeadline(), "距比赛结束：", "比赛未开始");
            setCountdownStart(countdownText);
            setPhaseStart(phaseText);
        }, 1000);

        const timerRound = setInterval(() => {
            const { countdownText, phaseText } = getCountdown(getDeadline1(), "当前轮次剩余：", "轮次未开始");
            setCountdownRound(countdownText);
            setPhaseRound(phaseText);
        }, 1000);

        return () => {
            clearInterval(timerStart);
            clearInterval(timerRound);
        };
    }, []);

    // 计算比赛开始倒计时
    const getDeadline = () => {
        const now = new Date();
        const startTime = new Date(new Date().setHours(9, 0, 0)); // 设置比赛开始时间，这里使用示例时间
        const TimeDuration = 3 * 60 * 60 * 1000; // 比赛周期时间

        if (now < startTime) {
            return startTime - now;
        } else {
            const diff = now - startTime;
            return TimeDuration - (diff % TimeDuration);
        }
    };

    // 计算当前轮次剩余倒计时
    const getDeadline1 = () => {
        const now = new Date();
        const startTimeAft = new Date(new Date().setHours(9, 0, 0)); // 设置当前轮次开始时间，这里使用示例时间
        const roundDuration = 15 * 60 * 1000; // 当前轮次周期时间

        if (now < startTimeAft) {
            return startTimeAft - now;
        } else {
            const diff = now - startTimeAft;
            return roundDuration - (diff % roundDuration);
        }
    };

    // 格式化倒计时为 xx h xx min xx s 的字符串
    const formatCountdown = (milliseconds) => {
        const hours = Math.floor(milliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
        return `${hours} h ${minutes} min ${seconds} s`;
    };

    // 根据倒计时和状态生成文本
    const getCountdown = (milliseconds, phaseTextRunning, phaseTextNotStarted) => {
        const now = new Date();
        const startTime = new Date(new Date().setHours(9, 0, 0)); // 设置比赛开始时间，这里使用示例时间

        if (now < startTime) {
            return {
                countdownText: formatCountdown(milliseconds),
                phaseText: phaseTextNotStarted,
            };
        } else {
            return {
                countdownText: formatCountdown(milliseconds),
                phaseText: phaseTextRunning,
            };
        }
    };

    const clickHandler = (path) => {
        navigate(path);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <>
            <AppBar position="static" style={{ height: "5vh" }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <NetworkPing sx={{ display: { xs: "none", md: "flex" } }} />
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                ml: 2,
                                display: { xs: "none", md: "flex" },
                                fontWeight: 400,
                                letterSpacing: ".3rem",
                                color: "inherit",
                                textDecoration: "none",
                            }}
                        >
                            一体化靶场
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page.name}
                                    onClick={() => clickHandler(page.path)}
                                    sx={{ my: 2, color: "white", display: "block" }}
                                >
                                    {page.name}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body1" sx={{ mr: 2 }}>
                                {phaseStart} {countdownStart} {/* 显示比赛开始倒计时 */}
                            </Typography>
                            <Typography variant="body1" sx={{ mr: 2 }}>
                                {phaseRound} {countdownRound} {/* 显示当前轮次剩余倒计时 */}
                            </Typography>

                            <IconButton onClick={handleOpenUserMenu}>
                                <Avatar
                                    sx={{
                                        bgcolor: "secondary.main",
                                    }}
                                />
                            </IconButton>
                            <Menu
                                sx={{ mt: "45px" }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem
                                    onClick={() => {
                                        logout();
                                        localStorage.removeItem("login");
                                        navigate("/login");
                                    }}
                                >
                                    <Typography textAlign="center">退出登录</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>

            <Outlet />
        </>
    );
}

export default ResponsiveAppBar;
