import { Flag } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { submitFlag } from "../../apis/home";
import ReactMarkdown from "react-markdown";

const ChallengItem = ({ name, score, status, clickHandler, category }) => {
  return (
    <Box
      onClick={clickHandler}
      sx={{
        ":hover": {
          cursor: "pointer",
          border: "2px solid #fff",
          borderRadius: "6px",
        },
        width: "100%",
        height: "100%",
        border: "2px solid transparent",
      }}
    >
      <Card
        sx={{
          borderRadius: "4px",
        }}
      >
        <Box xs={3}>
          <CardContent
            sx={{
              bgcolor: status ? "#4caf50" : "#474F5B",
              height: "10rem",
              width: "10rem",
            }}
          >
            <Stack
              direction="row"
              gap={1}
              alignContent={"center"}
              alignItems={"center"}
              sx={{
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  textAlign: "center",
                  lineHeight: "14px",
                }}
                color="#ccc"
              >
                题目
              </Typography>
              {category && (
                <Typography
                  level="body-sm"
                  sx={{
                    color: "#E2E3E5",
                    fontSize: "12px",
                    background: "#1E1E1E",
                    width: "fit-content",
                    padding: "3px",
                    minWidth: "30px",
                    textAlign: "center",
                    borderRadius: "5px",
                  }}
                >
                  {category}
                </Typography>
              )}
            </Stack>
            <Typography variant="h6">{name}</Typography>
            <Chip
              sx={{
                mt: 1,
              }}
              size="small"
              icon={<Flag />}
              label={score}
            />
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
};

const Challenges = ({ selfChallenge, refreshSelfChallenge }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [challengeInfo, setChallengeInfo] = useState({
    name: "正在加载",
    intro: "",
    hint: "",
    score: 0,
    status: false,
    content: "",
  });
  const [flag, setFlag] = useState("");
  // -1: 无状态  0:加载中  1: 失败 2:成功
  const [flagStatus, setFlagStatus] = useState(-2);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  function dialogCloseHandler() {
    setFlagStatus(-2);
    setFlag("");
    setModalOpen(false);
    setOpenSnackBar(false);
  }

  function getFlagColor() {
    switch (flagStatus) {
      case 0:
        return "primary";
      case 1:
        return "error";
      case -1:
        return "error";
      case 2:
        return "success";
      default:
        return "primary";
    }
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Divider textAlign="left">
          <Typography variant="h5">CHALLENGES</Typography>
        </Divider>
        <Box>
          <Grid
            container
            spacing={4}
            direction="row"
            // justifyContent="space-between"
            alignItems="stretch"
            sx={{
              mt: 3,
            }}
          >
            {selfChallenge?.map((item) => (
              <Grid item key={item.name}>
                <ChallengItem
                  clickHandler={async () => {
                    setChallengeInfo(item);
                    setModalOpen(true);
                  }}
                  {...item}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Dialog fullWidth open={modalOpen} onClose={dialogCloseHandler}>
        {flagStatus !== -2 && (
          <Alert
            severity={
              flagStatus === 2 ? "success" : flagStatus === 1 ? "error" : "info"
            }
            sx={{ mb: 2 }}
          >
            {flagStatus === 2 && "已提交正确flag!"}
            {flagStatus === 1 && "抱歉,flag错误!"}
            {flagStatus === 0 && "正在提交flag..."}
            {flagStatus === -1 && "请输入flag!"}
          </Alert>
        )}
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {challengeInfo.name}
        </DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Chip
            sx={{
              height: "2.5rem",
              width: "8rem",
            }}
            size="small"
            icon={<Flag />}
            label={challengeInfo.score}
          />
        </Box>
        <DialogContent>
          <DialogContentText
            color={{
              color: "#fff",
            }}
          >
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <p
                style={{
                  color: "lightgreen",
                  marginRight: ".3rem",
                }}
              >
                INTRO:
              </p>
              {challengeInfo.intro}
            </div>
            <div>
              <span
                style={{
                  color: "lightgreen",
                  marginRight: ".3rem",
                }}
              >
                HINT:
              </span>
              <ReactMarkdown>{challengeInfo.hint}</ReactMarkdown>
            </div>
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 3,
            }}
          >
            <TextField
              autoFocus
              error={flagStatus === 1}
              // color={getFlagColor()}
              margin="dense"
              id="name"
              label="请输入 flag"
              type="email"
              fullWidth
              variant="outlined"
              size="small"
              height="2.5rem"
              value={flag}
              onChange={(e) => {
                e.preventDefault();
                setFlag(e.target.value);
              }}
            />
            <Button
              variant="outlined"
              size="small"
              loading={flagStatus === 0}
              color={getFlagColor()}
              onClick={async () => {
                if (flag === "") {
                  setFlagStatus(-1);
                  return;
                }
                setFlagStatus(0);
                try {
                  const res = await submitFlag({
                    name: challengeInfo.id,
                    flag,
                  });
                  const code = Number(res.code);
                  if (code !== 0) {
                    setFlagStatus(1);
                    return;
                  }
                  setOpenSnackBar(true);
                  setFlagStatus(2);
                  // 刷新 selfChallenge
                  refreshSelfChallenge();
                } catch (e) {
                  setFlagStatus(1);
                }
              }}
              sx={{
                color: "#fff",
                height: "2.5rem",
                ml: 2,
                border: "1px solid #6B717B",
              }}
            >
              提交
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={openSnackBar}
        direction="down"
        autoHideDuration={3}
      >
        <Alert
          sx={{
            bgcolor: "#4caf50",
            color: "#fff",
          }}
          severity="success"
        >
          恭喜！！Flag正确
        </Alert>
      </Snackbar>
    </>
  );
};

export default Challenges;
