/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, CssBaseline, Grid, Typography } from "@mui/material";
import NewList from "./NewList";
import Challenges from "./Challenges";
import { useEffect, useState } from "react";
import {
  getBoard,
  getSelfChallenge,
  getSolveList,
  getSolvedlist,
} from "../../apis/home";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import { Base64Decode } from "../../utils/formate";

const Home = () => {
  const [board, setBoard] = useState(``);
  const [selfChallenge, setSelfChallenge] = useState([]);
  const [solveList, setSolveList] = useState([]);

  const navigate = useNavigate();

  const refreshSelfChallenge = async () => {
    const _selfChallenge = await getSelfChallenge();
    const { code } = _selfChallenge;
    if (code === -1) {
      return false;
    }
    const challenges = _selfChallenge.data;

    const res = await getSolvedlist();
    let solvedList = res.data;
    solvedList = solvedList.map((item) => item.challenge_id);

    challenges.forEach((challenge) => {
      if (solvedList.includes(challenge.id)) {
        challenge.status = true;
      } else {
        challenge.status = false;
      }
    });

    setSelfChallenge(challenges);

    return true;
  };

  const polling = () => {
    let timer;

    if (timer) {
      clearInterval(timer);
    }

    timer = setInterval(async () => {
      try {
        let _solveList;
        _solveList = await getSolveList();
        const solveListData = _solveList.data;
        setSolveList(solveListData);
      } catch {}
    }, 5000);
  };

  useEffect(() => {
    (async () => {
      try {
        const _board = await getBoard();
        const boardData = Base64Decode(_board.data);
        setBoard(boardData);

        if (!(await refreshSelfChallenge())) {
          navigate("/login");
        }
        let _solveList;
        _solveList = await getSolveList();
        const solveListData = _solveList.data;
        setSolveList(solveListData);
        polling();
      } catch (e) {
        // console.log(e);
        navigate("/login");
      }
    })();
  }, []);

  return (
    <>
      <Container>
        <CssBaseline />
        <main>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            wrap="nowrap"
            sx={{
              mt: 4,
              mb: 4,
            }}
          >
            <Grid item xs={10}>
              <Box
                sx={{
                  bgcolor: "primary.light",
                  height: "20rem",
                  width: "60rem",
                  borderRadius: "1rem",
                  p: 3,
                  overflow: "auto",
                }}
              >
                <Typography
                  sx={{
                    mb: 1,
                    fontWeight: "bold",
                    color: "#ccc",
                  }}
                >
                  公告
                </Typography>
                <Box
                  sx={{
                    minWidth: "50rem",
                    color: "#B7E6FA",
                  }}
                >
                  <ReactMarkdown>{board}</ReactMarkdown>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: 10,
                }}
              >
                <Challenges
                  refreshSelfChallenge={refreshSelfChallenge}
                  selfChallenge={selfChallenge}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={10}
              sx={{
                ml: 4,
              }}
            >
              <NewList solveList={solveList} />
            </Grid>
          </Grid>
        </main>
      </Container>
    </>
  );
};

export default Home;
